<template>
  <div class="card mb-3 shadow-sm sticky-md-top">
    <div class="card-body">
      <!-- Tab Navigation -->
      <ul
        class="nav nav-pills align-items-center"
        id="pills-tab"
        role="tablist"
      >
        <!-- Marketplace -->
        <div class="col">
          <h5 class="m-0">
            <i class="fas fa-tags"></i>
            {{
              this.$route.params.id != undefined
                ? $t("editCoupon")
                : $t("addCoupon")
            }}
          </h5>
        </div>
        <!-- ./Marketplace -->

        <!-- Submit Button -->
        <li class="nav-item ml-md-auto mx-auto mx-md-0">
          <div class="row align-items-center">
            <div class="col pl-0">
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="saveForm"
              />
            </div>
          </div>
        </li>
        <!-- ./Submit Button -->
      </ul>
      <!-- Tab Navigation -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Navigation",
  props: {
    submit: {
      default: false,
    },
    errors: {
      default: () => [],
    },
  },
  methods: {
    saveForm() {
      this.$emit("save-form");
    },
    checkError(type) {
      return this.errors.includes(type);
    },
  },
  computed: {
    checkPermission() {
      return permission.check("coupon", "u");
    },
    ...mapState(["coupon"]),
  },
};
</script>
